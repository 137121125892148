import React from "react";

const About = () => {
  return (
    <>
      <section id="about" className="app-about-section">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>About Our App</h2>
            <p>
            Clic.World adapts to a dynamic digital landscape by prioritizing a robust, adaptable, and scalable infrastructure. Emphasizing holistic, lifestyle-centric solutions, it caters to evolving market demands, ensuring sustainability across financial and business realms for our community's needs.
            </p>
          </div>
         
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div
                className="app-about-text"
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="200"
              >
                <h3>Best Mobile App</h3>
                <p>
                The reason why traditional banking have failed in the African financial space can be found in the flawed centralised capitalistic model that these banks have applied. The African financial space is fundamentally socialistic and have always been decentralised, based on communities and groups where savings and loans were handled by the group or community.
                </p>
                <p>
                Today, this model makes up more than 80% of the consumer financial activities and explains why decades and millions of dollars later the “bank the unbanked” efforts by mainly western NGO’s have failed. In reality the majority of the African population were not “unbanked”, they were simply “socially banked” or “community banked.
                </p>

                <ul>
                  <li>
                    <i className="icofont-verification-check"></i> Responsive
                    design
                  </li>
                  <li>
                    <i className="icofont-verification-check"></i> Easy to
                    customize
                  </li>
                  <li>
                    <i className="icofont-verification-check"></i> Modern design
                  </li>
                </ul>

                <a
                  href="https://play.google.com/store/apps"
                  className="default-button"
                  target="_blank"
                >
                  <i className="icofont-cloud-download"></i>
                  Download Now
                </a>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="text-center"
                data-aos="fade-in"
                data-aos-duration="3000"
                data-aos-delay="300"
              >
                <img src="/images/app-about.png" alt="App About Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
