import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <>
      <section id="faqs" className="faqs-section gray-bg">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>Frequently Asked Questions</h2>
            <p>
            Welcome to our FAQs page! Here, we aim to address your queries and provide clear insights into our app's functionalities. Find answers to common questions about security, transactions, joining cooperatives, fees, marketplace benefits, and developer tools.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5">
              <div
                className="text-center"
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="200"
              >
                <img src="/images/faq-img.png" alt="Faq Image" />
              </div>
            </div>

            <div className="col-lg-7">
              <div
                className="faq-content"
                data-aos="fade-in"
                data-aos-duration="3000"
                data-aos-delay="300"
              >
                <Accordion preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>1.</span> How secure is the app for financial transactions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Our app prioritizes security. We implement robust encryption protocols, multi-factor authentication, and regular security updates to safeguard user data and ensure secure financial transactions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>2.</span> What currencies are supported for transactions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Our app supports a wide range of currencies, facilitating seamless transactions globally. Users can transact in various currencies, enhancing flexibility in international money transfers.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>3.</span> Can I join savings and credit cooperatives (Saccos) through the app?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Absolutely! You can easily join Saccos using our app. Explore opportunities to join savings groups, access Saccos with high liquidity, and benefit from community-driven financial activities.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>4.</span> Are there any fees for using the app?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      We aim for transparency. While some services may have nominal fees, many basic functionalities are free to use. Detailed fee structures are available within the app for user convenience.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>5.</span> How can merchants benefit from the app's marketplace?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Merchants can leverage our marketplace by listing their goods/services, facilitating secure transactions, accessing business-specific tools, and tapping into a thriving ecosystem for business growth.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span>6.</span>  Are there developer tools available for integration with the app?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Yes! We offer a suite of developer tools and APIs enabling seamless integration and fostering innovation. Explore opportunities to customize and enhance user experiences within our app ecosystem.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
