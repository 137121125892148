import React from "react";
import Slider from "react-slick";

// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialData = [
  {
    feedbackText:
      "Clic's marketplace tools revolutionized my online business. Listing products, transacting securely, and accessing tailored merchant features boosted my sales remarkably",
    image: "/images/client/1.png",
    name: "David Rodriguez",
    designation: "E-commerce Entrepreneur",
  },
  {
    feedbackText:
      "Using Clic's Social Banking features helped our community immensely. Facilitating loans within our savings group was seamless, fostering financial growth for everyone.",
    image: "/images/client/2.png",
    name: "Dave",
    designation: "Community Organizer",
  },
  {
    feedbackText:
      "Clic ensures security and convenience for my cross-border transactions. Handling currency conversions and international transfers has never been smoother!",
    image: "/images/client/3.png",
    name: "Brian",
    designation: "Freelance Consultant",
  },
  {
    feedbackText:
      "Clic has transformed how I manage my finances! Joining Saccos and managing savings groups has never been easier. Highly recommended for community-driven financial growth",
    image: "/images/client/4.png",
    name: "Miles",
    designation: "Small Business Owner",
  },
];

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ]
  };

  return (
    <>
      <section id="testimonial" className="testimonial-section">
        <div className="container">
          <Slider {...settings}>
            {testimonialData.map((value, i) => (
              <div key={i} className="testimonial-item">
                <i className="icofont-quote-left"></i>
                <p className="description">
                  <q>{value.feedbackText}</q>
                </p>
                <div className="testimonial-content">
                  <div className="pic">
                    <img src={value.image} alt={`${value.name}'s testimonial`} />
                  </div>
                  <h3 className="title">{value.name}</h3>
                  <span className="post">{value.designation}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
