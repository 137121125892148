import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

const howItWorksData = [
  {
    icon: "icofont-login",
    title: "Login / Signup",
    text: "Upon opening the app, users encounter a welcoming login/signup screen. New users can swiftly register by providing essential details or opt for social login methods for a seamless entry. Existing users log in using their credentials or biometric authentication for enhanced security",
    duration: "1000",
    delay: "100",
  },
  {
    icon: "icofont-test-tube-alt",
    title: "Personal Dashboard",
    text: "After successful login, users land on their personalized dashboard. Here, they access an overview of their account, including balances in various currencies, recent transactions, and essential notifications regarding account activity or new app features.",
    duration: "2000",
    delay: "200",
  },
  {
    icon: "icofont-chart-histogram-alt",
    title: "Fund Management",
    text: "Users navigate to the fund management section, allowing them to perform various financial activities. They can initiate peer-to-peer transfers, convert currencies with ease, deposit money using multiple methods (like mobile payments or bank transfers), and create sub-wallets for specific purposes",
    duration: "3000",
    delay: "300",
  },
  {
    icon: "icofont-light-bulb",
    title: "Transactions and Payments",
    text: "This step enables users to conduct diverse transactions. They seamlessly send money across borders, make payments to the Kenya Revenue Authority (KRA), pay bills (such as airtime, water, electricity), and execute interbank transfers to various banks in Kenya and Uganda, ensuring comprehensive financial management.",
    duration: "4000",
    delay: "400",
  },
  {
    icon: "icofont-light-bulb",
    title: "Social Banking Features",
    text: "Users explore the social banking functionalities designed to facilitate community-driven financial activities. They can join savings and credit cooperatives (Saccos), create savings groups, manage group activities, offer and obtain loans within the group, and access Saccos with excellent liquidity for enhanced financial opportunities.",
    duration: "4000",
    delay: "400",
  },
  {
    icon: "icofont-light-bulb",
    title: "Marketplace and Business Tools",
    text: "The final step focuses on empowering users in the marketplace. Users can list their goods and services, transact securely within the platform, and utilize merchant-specific tools designed to enhance their business, facilitating seamless payments for goods and services and empowering merchants for success.",
    duration: "4000",
    delay: "400",
  },
];

const HowItWorks = () => {
  // To open the lightbox change the value of the "toggler" prop.
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <section id="how-it-works" className="how-it-works gray-bg">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>How it works?</h2>
            <p>
            This structured guide serves as a navigational flow for users within the app, ensuring a smooth and intuitive experience across various functionalities and features.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="hiw-feature-content">
                {howItWorksData &&
                  howItWorksData.map((value, i) => (
                    <div
                      className="single-hiw-feature"
                      data-aos="fade-in"
                      data-aos-duration={value.duration}
                      data-aos-delay={value.delay}
                      key={i}
                    >
                      <i className={value.icon}></i>
                      <h4>{value.title}</h4>
                      <p>{value.text}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <div
                className="video-demo-content"
                data-aos="fade-in"
                data-aos-duration="6000"
                data-aos-delay="600"
              >
                <img
                  src="/images/video-demo2.jpg"
                  alt="Video Demo Image"
                  className="img-circle"
                />

                <div className="play-video-icon">
                  <div
                    className="popup-video"
                    onClick={() => setToggler(!toggler)}
                  >
                    <i className="icofont-play"></i>
                    <div className="sonar-wrapper">
                      <div className="sonar-emitter">
                        <div className="sonar-wave"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/q5DsVVurRQ8"]}
      />
    </>
  );
};

export default HowItWorks;
