import React from "react";

const featuresData = [
  {
    icon: "icofont-sun",
    title: "FINANCIAL HUB",
    text: "This fixture serves as the app's financial control center, enabling fund management activities like peer-to-peer transfers, currency conversions, and flexible deposit methods for seamless financial control.",
    duration: "1000",
    delay: "100",
  },
  {
    icon: "icofont-light-bulb",
    title: "TRANSACTION CAPABILITES",
    text: "Explore a variety of transactions including international money transfers, payments to authorities, interbank transfers, and bill payments, ensuring comprehensive financial interaction within the app.",
    duration: "2000",
    delay: "200",
  },
  {
    icon: "icofont-code-alt",
    title: "SECURITY AND PRIVACY MEASURES",
    text: " Embrace robust security measures safeguarding user data, transactions, and privacy, ensuring a secure and trustworthy environment for all financial interactions.",
    duration: "4000",
    delay: "400",
  },
  {
    icon: "icofont-eye",
    title: "SOCIAL BANKING",
    text: "Engage in community-driven financial activities by joining savings groups, accessing Saccos with high liquidity, actively managing group activities, and facilitating group loans for mutual benefit, growth, and empowerment.",
    duration: "5000",
    delay: "500",
  },
  {
    icon: "icofont-space-shuttle",
    title: "MARKET PLACE AND BUSINESS TOOLS",
    text: "List goods/services, transact securely, access merchant-specific tools, and business-enhancing features, empowering merchants within the app's thriving marketplace ecosystem.",
    duration: "6000",
    delay: "600",
  },
  {
    icon: "icofont-imac",
    title: "DEVELOPERS TOOLS AND APIS",
    text: "Discover the suite of developer tools and APIs enabling seamless integration and development opportunities, fostering innovation, robust customization, and enhanced functionalities within the app's ecosystem.",
    duration: "3000",
    delay: "300",
  }
];

const Features = () => {
  return (
    <>
      <div id="features" className="awsome-features pt-100 pb-70">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>Awsome Features</h2>
            <p>
            Discover Versatile App Features! From seamless fund management to secure transactions, community-driven banking, and robust security measures. Explore our app's capabilities today!
            </p>
          </div>

          <div className="row justify-content-center">
            {featuresData &&
              featuresData.map((value, i) => (
                <div className="col-md-6 col-lg-4" key={i}>
                  <div
                    className="single-feature mb-30"
                    data-aos="fade-in"
                    data-aos-duration={value.duration}
                    data-aos-delay={value.delay}
                  >
                    <div className="feature-icon">
                      <i className={value.icon}></i>
                    </div>
                    <h4>{value.title}</h4>
                    <p>{value.text}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
