import React from "react";

const Blog = () => {
  return (
    <>
      <section id="blog" className="blog-section">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>Latest Blog Post</h2>
            <p>
            Welcome to our vibrant blogging hub! Dive into insightful articles designed to enrich your financial knowledge, explore new features, and stay updated on the latest trends. Let these blogs be your guide to a seamless and empowered financial journey.
            </p>
          </div>

          <div
            className="horizontal-post single-blog-post"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="200"
          >
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="blog-img">
                  <a href="/details-one">
                    <img src="/images/blog/post1.jpg" alt="Blog Post Image" />
                  </a>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="blog-info">
                  <h6 className="blog-title">
                    <a href="/details-one">
                    Navigating Cross-Border Transactions
                    </a>
                  </h6>

                  <ul className="post-admin">
                    <li>By <a href="#">Admin</a></li>
                    {/* <li>3 Comments</li> */}
                    <li>10 Feb, 2023</li>
                  </ul>

                  <p>
                  Discover seamless ways to conduct international transactions, understanding currency conversions, transfer fees, and best practices for hassle-free cross-border financial interactions.
                  </p>

                  {/* <a
                    href="/details-one"
                    className="default-button"
                  >
                    Read More
                    <i className="icofont-swoosh-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="horizontal-post single-blog-post"
            data-aos="fade-in"
            data-aos-duration="3000"
            data-aos-delay="300"
          >
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="blog-img">
                  <a href="/details-one">
                    <img src="/images/blog/post2.jpg" alt="Blog Post Image" />
                  </a>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="blog-info">
                  <h6 className="blog-title">
                    <a href="/details-one">
                    The Dynamics of Marketplace Success
                    </a>
                  </h6>
                  
                  <ul className="post-admin">
                    <li>By <a href="#">Admin</a></li>
                    {/* <li>3 Comments</li> */}
                    <li>11 Feb, 2023</li>
                  </ul>

                  <p>
                  Uncover the strategies for success within online marketplaces, exploring effective product listings, secure transactions, merchant tools, and optimizing sales within digital marketplaces.
                  </p>

                  {/* <a
                    href="/details-one"
                    className="default-button"
                  >
                    Read More
                    <i className="icofont-swoosh-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="horizontal-post single-blog-post"
            data-aos="fade-in"
            data-aos-duration="4000"
            data-aos-delay="400"
          >
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="blog-img">
                  <a href="/details-one">
                    <img src="/images/blog/post3.jpg" alt="Blog Post Image" />
                  </a>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="blog-info">
                  <h6 className="blog-title">
                    <a href="/details-one">
                    Empowering Communities Through Social Banking
                    </a>
                  </h6>
                  
                  <ul className="post-admin">
                    <li>By <a href="#">Admin</a></li>
                    {/* <li>3 Comments</li> */}
                    <li>12 Feb, 2023</li>
                  </ul>

                  <p>
                  Explore the transformative impact of social banking in empowering communities, including Sacco memberships, group savings, loan facilitation, and community financial growth.
                  </p>

                  {/* <a
                    href="/details-one"
                    className="default-button"
                  >
                    Read More
                    <i className="icofont-swoosh-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
