import React from "react";
import Slider from "react-slick";

// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const screenshotsData = [
  {
    image: "/images/screenshot/screenshots1.png",
  },
  {
    image: "/images/screenshot/screenshots2.png",
  },
  {
    image: "/images/screenshot/screenshots3.png",
  },
  {
    image: "/images/screenshot/screenshots4.png",
  },
  {
    image: "/images/screenshot/screenshots5.png",
  },
];

const Screenshots = () => {
  const settings = {
    dots: true, // Enables navigation dots
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ]
  };

  return (
    <>
      <section id="screenshots" className="app-screenshots-section">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>App Screenshots</h2>
            <p>
              Welcome to a Visual Tour of Clic – Your All-in-One Financial Solution! Explore our app's functionality and user-friendly interface through these screenshots. Get a glimpse of the seamless transactions, community-driven features, and secure financial management offered by Clic. Discover how simplicity and innovation meet to empower your financial journey!
            </p>
          </div>

          <Slider {...settings}>
            {screenshotsData.map((value, i) => (
              <div key={i} className="screenshot-item">
                <img src={value.image} alt={`App Screenshot ${i + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Screenshots;
