import React from "react";
import Slider from "react-slick";
import VisibilitySensor from "react-visibility-sensor";

// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderData = [
  {
    heading: "Clic's Social Banking Revolution",
    text: "Empower your community financially through Clic's innovative Social Banking tools. Join Saccos, manage savings groups, and facilitate loans for mutual growth and prosperity.",
    image: "/images/app-mockup-1.png",
  },
  {
    heading: "Clic's Marketplace Empowerment",
    text: "Maximize business potential using Clic's marketplace tools. List goods/services securely, transact conveniently, and empower merchants within our thriving marketplace.",
    image: "/images/app-mockup-2.png",
  },
];

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    fade: true
  };

  return (
    <>
      <div id="home" className="homepage-slides-wrapper">
        <Slider {...settings}>
          {sliderData.map((item, index) => (
            <div key={index} className="single-slider-item gradient-bg wave-bg-two">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-7">
                    <VisibilitySensor>
                      {({ isVisible }) => (
                        <div className="slider-text">
                          <h1 className={isVisible ? "animated fadeInUp slow opacityOne" : ""}>
                            {item.heading}
                          </h1>
                          <p className={isVisible ? "animated fadeInUp slow opacityOne" : ""}>
                            {item.text}
                          </p>
                          <div className="slide-button">
                            <a
                              href="https://play.google.com/store/apps"
                              target="_blank"
                              className={`slide-btn-white mr-10 ${isVisible ? "animated fadeInDown slow opacityOne" : ""}`}
                            >
                              <i className="icofont-cloud-download"></i> Download
                            </a>
                            <a
                              href="#"
                              className={`slide-btn-bordered ${isVisible ? "animated fadeInDown slow opacityOne" : ""}`}
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      )}
                    </VisibilitySensor>
                  </div>
                  <div className="col-md-12 col-lg-5">
                    <div className="welcome-phone">
                      <img src={item.image} alt="App Mockup" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default HomeSlider;
